@import 'src/styles/services';

.root {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  height: 100%;
  width: 100%;
  padding: 32px 10px;

  @include media-width-down($screen-850) {
    row-gap: 16px;
    padding: 16px 10px;
    padding-top: 0;
  }
}

.buttons {
  padding: 8px 0;
  display: none;

  .promoButton {
    width: 100%;
    padding-right: 10px;
  }

  @include media-width-down($screen-850) {
    display: flex;
  }
}

.top {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  &:empty {
    display: none;
  }
}

.subSection {
  --margin-top: 8px;
}

.section,
.subSection {
  + .section,
  + .subSection {
    margin-top: var(--margin-top);
  }

  @include media-width-down($screen-850) {
    margin-top: var(--margin-top);
  }
}
