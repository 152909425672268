@import 'src/styles/services';

.root.root {
  color: $color-grey;
  transition: trs(color);

  svg {
    width: 100%;
    height: 100%;
  }

  &.active {
    color: $color-yellow;

    &.yellow {
      color: $color-yellow;
    }

    &.red {
      color: $color-red;
    }
  }
}
