@import 'src/styles/services';

.root {
  width: 100%;
  margin-right: -10px;
  margin-top: 4px;

  .banner {
    margin-right: 0;
  }
}

@include media-width-down($screen-sm) {
  .root {
    width: calc(100% + 16px);
  }
}

@include media-width-down($screen-xs) {
  .banner {
    max-width: 340px;
  }
}
