@import 'src/styles/services';

.root {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &.isOneCompetitor {
    text-align: left;
    align-items: flex-start;
    justify-content: center;

    .liveStatus {
      align-items: flex-start;
      margin-top: 0;
    }

    .pregameStatus {
      align-items: flex-start;
    }

    .pregameTime {
      font-size: 15px;
      line-height: 1;
    }
  }
}

.statusWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.score {
  display: flex;
  gap: 8px;
  font-size: 22px;
  line-height: 1;
  font-weight: 900;
  letter-spacing: 0.05em;
  text-align: center;
}

.icons {
  margin-top: 8px;
  display: flex;
  gap: 8px;
}

.icon {
  font-size: 20px;
  padding: 0;
  border: none;
  color: $color-grey;
}

@include media-width-down($screen-850) {
  .root {
    &.isOneCompetitor {
      .pregameTime {
        font-size: 13px;
        line-height: 16px;
      }
    }
  }

  .statusWrapper {
    gap: 4px;
  }

  .icons {
    margin-top: 4px;
    gap: 12px;
  }
}
