@import 'src/styles/services';

.root {
  display: flex;
  align-items: center;
  justify-items: center;
  min-width: 20px;
  min-height: 20px;
}

.icon {
  width: 100%;
  height: 100%;
  color: $color-grey;
}
