@import 'src/styles/services';

.root {
  display: flex;
  align-items: center;
  gap: 8px;
}

.image {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.unknownLogo {
  width: 100%;
  height: 100%;
}

.nameContainer {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.name {
  font-size: 13px;
  line-height: 20px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.column {
  flex-direction: column;
  max-width: 115px;

  .nameContainer {
    max-width: 100%;
    max-height: 32px;
  }

  .name {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    word-break: break-all;
    white-space: break-spaces;
    text-align: center;
  }
}

.row {
  flex-direction: row;
  max-width: calc(100% - 20px - 8px);

  .nameContainer {
    max-width: calc(100% - 20px - 8px);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .name {
    white-space: nowrap;
  }
}

.rowReverse {
  flex-direction: row-reverse;

  .name {
    white-space: nowrap;
  }
}
