@import 'src/styles/services';

.root {
  display: grid;
  grid-row-gap: 1px;
  row-gap: 1px;
  border-radius: $border-lg;
  overflow: hidden;
  border: 1px solid $color-yellow;
  width: 376px;
  background-color: $color-black;
}

.row {
  background-color: $color-black-light;
}

.header {
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  align-content: center;
  grid-column-gap: 8px;
  column-gap: 8px;
  padding: 16px;
  cursor: pointer;
  color: $color-grey-light;
  height: 100%;

  &.withFavorite {
    grid-template-columns: 20px 1fr 20px;
  }
}

.headerIconWrapper {
  color: $color-grey;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
  }
}

.headerTitle {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.headerLink {
  text-decoration: none;
  color: inherit;
  transition: trs(color);

  @include hover {
    color: $color-yellow;
  }
}

.contentRow {
  position: relative;
  min-height: 108px;

  .live & {
    &::before {
      content: '';
      position: absolute;
      height: 48px;
      width: 3px;
      left: 0;
      top: 24px;
      background-color: $color-yellow;
      box-shadow: 0 2px 10px 0 $color-yellow;
    }

    &::after {
      content: '';
      position: absolute;
      height: 3px;
      width: 72px;
      left: 50%;
      top: -1px;
      transform: translateX(-50%);
      background-color: $color-red;
      box-shadow: 0 2px 10px 0 $color-red;
    }
  }
}

.content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  padding: 16px;
  align-items: start;
  justify-items: center;
  height: 100%;
  overflow: visible;

  .teamName {
    display: block;
    max-height: 38px;
    white-space: break-spaces;
    word-break: normal;
  }

  &.isOneCompetitor {
    grid-template-columns: 180px 1fr;
    justify-items: start;
    align-items: center;

    .team {
      max-width: 100%;
      max-height: unset;
      margin-top: 0;
    }

    .teamNameContainer {
      overflow: visible;
      text-overflow: initial;
    }

    .teamName {
      -webkit-line-clamp: 2;
      text-align: left;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      white-space: initial;
    }
  }
}

.outcomes {
  padding: 16px;
}

.outcomesWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1px;
  column-gap: 1px;
  background-color: $color-black-light;
  border-radius: $border-sm;
}

.moreBetsWrapper {
  .moreBets {
    font-size: 14px;
    height: 38px;
    border-radius: $border-sm;
    box-shadow: none;
  }
}

.outcome {
  &.outcome {
    background-color: $color-grey;
    border-radius: 0;
    font-size: 13px;
    line-height: 1;
    min-height: 36px;
    min-width: 100%;
    position: relative;

    &::before {
      @include outcome-bg-increase;
    }

    &::after {
      @include outcome-bg-decrease;
    }
  }

  &:first-of-type {
    border-radius: $border-sm 0 0 $border-sm;
  }

  &:last-of-type {
    border-radius: 0 $border-sm $border-sm 0;
  }

  &.oddActive {
    background: $color-red-light-2;

    .oddTitle {
      color: rgba(255, 255, 255, 0.65);
    }
  }

  &.oddEmpty {
    justify-content: center;
  }

  &.oddIncrease {
    &::before {
      opacity: 1;
    }
  }

  &.oddDecrease {
    &::after {
      opacity: 1;
    }
  }
}

@include media-width-down($screen-850) {
  .root {
    width: 320px;
  }

  .header {
    padding: 8px;
  }

  .contentRow {
    min-height: 90px;
  }

  .content {
    padding: 8px;

    &.isOneCompetitor {
      grid-template-columns: 160px 1fr;
    }
  }

  .outcomes {
    padding: 8px;
  }
}
