@mixin outcome-bg-increase {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(rgba(112, 255, 0, 0.45), rgba(117, 215, 81, 0), rgba(112, 255, 0, 0)),
    url('/assets/sport/icons/outcomeButtonIncrease.svg');
  background-repeat: no-repeat;
  background-position: center top;
  opacity: 0;
  transition: opacity 0.7s ease;
}

@mixin outcome-bg-decrease {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(rgba(215, 81, 81, 0), rgba(215, 81, 81, 0), rgba(255, 0, 0, 0.45)),
    url('/assets/sport/icons/outcomeButtonDecrease.svg');
  background-repeat: no-repeat;
  background-position: center bottom;
  opacity: 0;
  transition: opacity 0.7s ease;
}
