@import 'src/styles/services';

.root {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.day {
  color: $color-grey;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
}

.time {
  font-size: 22px;
  font-weight: 900;
  line-height: 24px;
  color: $color-yellow;
  letter-spacing: 0.05em;
}
