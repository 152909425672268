@import 'src/styles/services';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: fit-content;
  min-width: 64px;
  padding: 6px 1px;
  background-color: $color-black;
  cursor: pointer;
  text-align: left;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  white-space: nowrap;
  transition: trs(background-color);
  overflow: hidden;
  position: relative;
  border: 1px solid transparent;

  &::before {
    pointer-events: none;

    @include outcome-bg-increase;
  }

  &::after {
    pointer-events: none;

    @include outcome-bg-decrease;
  }

  &.horizontal {
    min-width: 98px;
    padding: 8px;
  }

  &.vertical {
    flex-direction: column;
  }

  &.active {
    background-color: $color-red;
  }

  &.empty {
    justify-content: center;
    align-items: center;
    background-color: $color-grey-2;
    color: $color-grey-light;
    border-color: transparent;
    font-weight: 500;
    pointer-events: none;
  }

  &.frozen:not(.empty) {
    background-color: $color-grey-2;
    color: $color-grey-light;
  }

  &.frozen:not(.active) {
    pointer-events: none;
  }

  &.increase {
    &::before {
      opacity: 1;
    }
  }

  &.decrease {
    &::after {
      opacity: 1;
    }
  }

  @include hover {
    border: 1px solid $color-white;
  }
}

.value {
  min-width: 38px; // необходимо для того чтобы когда изменились значения до 99.99 не было изменения ширины
  color: $color-white;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 600;
  position: relative;
  transition: trs(color, opacity);

  .vertical & {
    text-align: center;
  }

  .increase & {
    color: $color-green-light;
  }

  .decrease & {
    color: $color-red;
  }

  .empty & {
    color: $color-grey-light;
  }

  .frozen:not(.empty) & {
    color: $color-grey-light;
  }
}

.title {
  max-width: 100%;
  color: $color-grey-light;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;

  .horizontal & {
    flex: 1 1 auto;
    margin-right: 8px;
  }

  .vertical & {
    text-align: center;
  }
}
