@import 'src/styles/services';

.root {
  position: relative;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  overflow: hidden;
  min-height: 192px;
  width: 100%;
  aspect-ratio: 469 / 192;
  background-image: url('/assets/banner/background.webp');
  background-repeat: no-repeat;
  background-position: left top;
  background-color: $color-black-light;
  background-size: cover;
  transition: trs(background-color);
  isolation: isolate;

  &:hover {
    background-color: $color-red;

    @include media-width-down($screen-sm) {
      background-color: $color-black-light;
    }
  }

  &.fix {
    width: min-content;
  }

  &.hasBackground {
    background-image: unset;

    &:hover {
      background-color: $color-black-light;
    }
  }

  @include media-width-down($screen-sm) {
    min-width: 340px;

    &:last-child {
      margin-right: 8px;
    }

    .button:hover {
      background-color: $color-white;
      color: $color-black-light;
    }
  }

  @include media-width-down($screen-xxs) {
    min-width: 330px;
  }
}

.bannerImage {
  position: absolute;
  right: 0;
  width: 180px;
  height: 180px;

  .root:hover & {
    display: none;

    @include media-width-down($screen-sm) {
      display: block;
    }
  }
}

.backgroundImage {
  object-fit: cover;
  object-position: right top;
}

.body {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  z-index: 1;
  flex-grow: 1;
}

.title {
  font-weight: bold;
  font-size: 22px;
  line-height: 1.2;
  text-transform: uppercase;
}

.content {
  font-size: 16px;
  line-height: 1.2;
  color: $color-grey-light;
}
