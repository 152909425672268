@import 'src/styles/services';

.root {
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: -14px;
}

.date {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: $color-grey;
}

.content {
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 16px;
  gap: 4px;
}

.time {
  color: $color-green;
  font-variant-numeric: lining-nums tabular-nums;
}

@include media-width-down($screen-850) {
  .root {
    gap: 4px;
    margin-top: -6px;
  }
}
